
.App {
  margin-top: 0.25rem;
}

.backgroundBox {
  height: 97vh;
  border-radius: 0.3rem;
  background-color: #282c34;

}
.TitleHead {
  margin: 1rem 0 ;
  text-align: center !important;
  color: #282c34;
  text-decoration: underline;
  font-family: 'Anton', sans-serif;
}

.menuBox {
  background-color: #3377ff;
  border-radius: 0.3rem 0 0 0.3rem;
  padding-right: 0 !important;
  display: grid;
  place-items: center;
  justify-content: end;
}

.aboutMe {
  display: grid;
  place-items: center;
}

.viewPage{
  background-color: #fafafa;
  border-radius: 0 0.3rem 0.3rem 0;
  padding: 0 !important;
}

.NavBar{
  list-style-type: none;
}

.NavBtn{
  color: white;
  text-decoration: none !important;
  cursor: pointer !important;
  border: 1px solid white;
  /* border-radius: 0.3rem 0.3rem 0.3rem 0.3rem; */
  border-radius: 0.3rem 0 0 0.3rem;
  display: block;
  text-align: center;
  margin: 0.4rem 0;
  padding: 0.8rem;
  font-size: 1rem;
  transition: background-color 250ms ease-in;
  border-right: none;
}

.NavBtn:hover {
  color: #3377ff;
  background-color: #e5ecfa;
}

.activeNavBtn {
  color: #3377ff;
  background-color: #e5ecfa;
}

.heading {
  font-family: 'Anton', sans-serif;
}

.text {
  font-family: 'Noto Sans TC', sans-serif;
}

.Button {
  border-radius: 0.3rem;
  padding: 0.5rem;
  cursor: pointer;
  text-decoration: none !important;
  background: #3377FF;
  color: white;
  display: block;
  transition: box-shadow 150ms ease-in;
  transition: background-color 100ms ease-in;


}

.Button:hover {
  box-shadow: 5px 4px 4px rgba(0,0,0,0.2);
  color: #fff;
  background-color: #5599ff;
}

.projectCard{
  border: 1px solid #ddd;
  transition: box-shadow 150ms ease-in;
}

.projectCard:hover {
  box-shadow: 5px 8px 8px rgba(0,0,0,0.2);
}

.pj-description{
  min-height: 8rem;
}

.proPicStyle {
  max-height: 400px;
  max-width: 400px;
  border: 10px solid #6b9cff;
  border-radius: 50%;
}
@media only screen and (max-width: 800px) {
  .proPicStyle {
    max-height: 250px;
    max-width: 250px;
    
  }
}
@media only screen and (max-width: 500px) {
  .proPicStyle {
    max-height: 200px;
    max-width: 200px;
  }
  .introduction{
    font-size: 2.5rem !important;
  }
  .introBottom {
    font-size: 1.4rem !important;
  }
}
.aboutMeImg{
  vertical-align: middle;
  display: table-cell;

}
.aboutMeP{
  font-size: 1.1rem;
}

.ProjectContainer{
  height: 80vh;
  overflow-y: auto;
  place-items: center;
}

.introTop{
  text-align: left;
  font-size: 1.2rem;
}

.introduction{
  position: absolute;
  color: white;
  text-align: center;
  font-size: 3rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.introBottom{
  font-size: 2rem;
  color: rgba(58, 124, 255, 0.952);
}
.error404{
  width: 20rem;
  height: 20rem;
}

.ErrorTxt {
  color: #ff335f;
}